import { useState, useEffect, useCallback, useContext, createContext, createRef, Fragment } from 'react';
import { useParams, Link } from 'react-router-dom';
import { i18n, getContents, getProducts, getProductsFilter, getProductsCategories } from '../utils';

/**
 * User Context
 */
export const UserContext = createContext(null);

/**
 * User Context Provider
 */
export function UserContextProvider({ config, children }) {
  const [load, _setLoad] = useState(0);
  const [site, _setSite] = useState(config.site || {});
  const [shop, _setShop] = useState(config.shop || {});
  const [cart, _setCart] = useState(config.cart || {});
  const [info, _setInfo] = useState(null);

  const setInfo = async (_info, _type) => {
    _setInfo({ text: _info, type: _type });
  };

  const setSite = async (_site = {}) => {
    localStorage.setItem('x-site-id', _site.id);
    _setSite(_site);
    _setLoad(load + 1);
  };

  const setShop = async (_shop = {}) => {
    localStorage.setItem('x-shop-id', _shop.id);
    _setShop(_shop);
    _setLoad(load + 1);
  };

  const setCart = async (_cart = []) => {
    localStorage.setItem('x-cart', JSON.stringify(_cart));
    _setCart(_cart);
    _setLoad(load + 1);
  };

  const setCartProduct = async (_item, _data) => {
    cart.items = cart.items || [];
    const _prev = cart.items.find(i => i._id === _item._id);
    if (!_prev) {
      cart.items.push(_item);
    }
    if (typeof _data === 'string') {
      _item.quantity = (_prev?.quantity || _item.quantity || 0) + (1 * parseInt(_data));
      _item.total = _item.price * _item.quantity;
    } else {
      _item.quantity = _data;
      _item.total = _item.price * _item.quantity;
    }
    if (_item.quantity) {
      setInfo(`${_item.title} ${_data}`, 'success')
    } else {
      setInfo(`${_item.title}`, 'danger')
    }
    cart.items = cart.items.filter((i) => i.quantity > 0);
    cart.total = cart.items.reduce((a, i) => a + i.total, 0);
    await setCart(cart);
  };

  return (
    <UserContext.Provider value={{ config, load, site, shop, cart, setSite, setShop, setCart, setCartProduct, info, setInfo }}>
      {children}
      <Popover />
    </UserContext.Provider>
  );
};

/**
 * Page Content
 */
export function PageContent({ route }) {
  const [items, setItems] = useState([]);
  const [start, setStart] = useState(0);
  const [limit, setLimit] = useState(1);

  const params = useParams();
  if (params.route) {
    route = params.route;
  }

  const _fetch = (_start = false, _limit = false) => {
    if (_start === false) {
      window.scrollTo(0, 0);
    }
    const filter = { route };
    setStart(filter.start = _start !== false ? _start : start);
    setLimit(filter.limit = _limit !== false ? _limit : limit);
    getContents(filter).then(setItems).catch(console.error);
  };

  useEffect(_fetch, [route]); // eslint-disable-line react-hooks/exhaustive-deps

  return items.length ? (<Fragment>{items.map((item, i) => (<ItemContent content={item} key={`${i}`} />))}</Fragment>) : '';
};

/**
 * Page Product
 */
export function PageProduct({ route }) {
  const [items, setItems] = useState([]);
  const [start, setStart] = useState(0);
  const [limit, setLimit] = useState(1);

  const params = useParams();
  if (params.route) {
    route = params.route;
  }

  const _fetch = (_start = false, _limit = false) => {
    if (_start === false) {
      window.scrollTo(0, 0);
    }
    const filter = { route };
    setStart(filter.start = _start !== false ? _start : start);
    setLimit(filter.limit = _limit !== false ? _limit : limit);
    getProducts(filter).then(setItems).catch(console.error);
  };

  useEffect(_fetch, [route]); // eslint-disable-line react-hooks/exhaustive-deps

  return items.length ? (<Fragment>{items.map((item, i) => (<ItemProduct product={item} key={`${i}`} />))}</Fragment>) : '';
};

/**
 * Page Products
 */
export function PageProducts({ route }) {
  const [items, setItems] = useState([]);
  const [itemsFilter, setItemsFilter] = useState([]);
  const [start, setStart] = useState(0);
  const [limit, setLimit] = useState(20);
  const [check, setCheck] = useState([]);

  const params = useParams();
  if (params.route) {
    route = Object.values(params).filter(v => !!v).join('/');
  }

  const _fetch = (_start = false, _limit = false, _check = false) => {
    if (_start === false) {
      window.scrollTo(0, 0);
    }

    const filter = { categories: route.split('/') };
    setStart(filter.start = _start !== false ? _start : start);
    setLimit(filter.limit = _limit !== false ? _limit : limit);
    setCheck(filter.check = _check !== false ? _check : check);

    getProducts(filter).then((r) => {
      setItems(r);
      setItemsFilter(getProductsFilter());
    }).catch(console.error);
  };

  const _fetchFilter = (item) => {
    item.check = !item.check;
    let _check = [...check].filter(c => c._id !== item._id);
    if (item.check) {
      _check.push(item);
    }
    _fetch(false, false, _check);
  }

  useEffect(_fetch, [route]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="item-products item-products-table">
      <div className="container-lg">
        <div className="row">
          <div className="col-lg-3 d-none d-lg-block">
            {itemsFilter?.length ? itemsFilter.map((item, i) => (
              <div className="mt-2" key={`${i}`}>
                <button className="btn btn-default" data-bs-toggle="collapse" data-bs-target={`#item-${i}`}>
                  <h6>{item.title}</h6>
                </button>
                <div className="list-group collapse" id={`item-${i}`}>
                  {item.items.map((option, o) => (
                    <button className="list-group-item list-group-item-action d-flex justify-content-between align-items-center" onClick={() => _fetchFilter(option)} key={`${o}`}>
                      <div><i className={`fa fa-fw fa-regular ${option.check ? 'fa-square-check' : 'fa-square'}`}></i>&nbsp;{option.value}</div>
                      <span className="badge bg-primary rounded-pill">{option.count}</span>
                    </button>
                  ))}
                </div>
              </div>
            )) : ''}
          </div>
          <div className="col-lg-9">
            <div className="row row-cols row-cols-sm-2 row-cols-md-3 row-cols-lg-4 gx-2 gy-2">
              {items.map((item, i) => (
                <div className="col" key={`${i}`}>
                  <Card card={item} />
                </div>
              ))}
            </div>
            <div className="row">
              <div className="col text-center p-4 m-4">
                <button className="btn btn-secondary p-4 m-4" onClick={() => _fetch(start + 1)}>{i18n('more_products')}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Item Content
 */
export function ItemContent({ content }) {
  return (
    <Fragment>
      {content.items.map((rows, r) => (
        <Fragment key={`${r}`}>
          {rows.class && rows.class.match('item-content-table') ? (<ItemContentTable table={rows} />) : ''}
          {rows.class && rows.class.match('item-content-slide') ? (<ItemContentSlide slide={rows} />) : ''}
          {rows.class && rows.class.match('item-content-newslatter') ? (<ItemContentNewslatter block={rows} />) : ''}
        </Fragment>
      ))}
    </Fragment>
  );
};

/**
 * Item Content Table
 */
export function ItemContentTable({ table }) {
  return (
    <div className={`item-content ${table.class || ''}`}>
      {table.badge || table.image || table.title || table.label ? (
        <div className="container-lg container-title position-relative">
          <div className="row">
            <div className="col-lg">
              <Node node={table} />
            </div>
          </div>
        </div>
      ) : ''}
      {table.items?.length ? (
        <div className="container-lg container-table position-relative">
          <div className="row row-cols gx-2 gy-2">
            {table.items.map((cols, c) => (
              <div className="col-lg" key={`${c}`}>
                {cols.badge || cols.image || cols.title || cols.label ? (
                  <Node node={cols} />
                ) : ''}
                {cols.items?.length ? cols.items.map((vals, v) => (
                  <Node node={vals} key={`${c}-${v}`} />
                )) : ''}
              </div>
            ))}
          </div>
        </div>
      ) : ''}
    </div>
  );
};

/**
 * Item Content Slide
 */
export function ItemContentSlide({ slide }) {
  const [cols, setCols] = useState([]);
  const [prev, setPrev] = useState(false);
  const [next, setNext] = useState(false);
  const ref = createRef();

  const _scroll = useCallback(() => {
    setPrev(ref.current.scrollLeft > 10);
    setNext(ref.current.scrollLeft < ref.current.scrollWidth - ref.current.offsetWidth - 10 && ref.current.scrollWidth > ref.current.offsetWidth);
  }, [ref]);

  const _next = () => {
    ref.current.scroll({ left: ref.current.scrollLeft + ref.current.offsetWidth, behavior: 'smooth' });
  };

  const _prev = () => {
    ref.current.scroll({ left: ref.current.scrollLeft - ref.current.offsetWidth, behavior: 'smooth' });
  };

  const _fetch = () => {
    ref.current.addEventListener('scroll', _scroll);
    _scroll();
  }

  const _items = () => {
    if (Array.isArray(slide.items)) {
      setCols(slide.items);
    } else if (slide.items.method === 'contents') {
      getContents(slide.items.params).then(setCols).catch(console.error);
    } else if (slide.items.method === 'products') {
      getProducts(slide.items.params).then(setCols).catch(console.error);
    } else if (slide.items.method === 'products-categories') {
      getProductsCategories(slide).then(setCols).catch(console.error);
    }
  };

  useEffect(_fetch, [_scroll]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(_items, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={`item-content ${slide.class || ''}`}>
      {slide.badge || slide.image || slide.title || slide.label ? (
        <div className="container-lg container-title position-relative">
          <div className="row">
            <div className="col">
              <Node node={slide} />
            </div>
          </div>
        </div>
      ) : ''}
      {cols ? (
        <div className="container-lg container-slide position-relative">
          <div className="row row-cols row-cols-sm-2 row-cols-md-4 row-cols-lg-6 overflow-auto flex-nowrap gx-2" ref={ref}>
            {cols.map((item, i) => (
              <div className="col" key={`${i}`}>
                <Card card={item} />
              </div>
            ))}
          </div>
          <button className="slide-prev d-none d-lg-block" onClick={_prev}>{prev ? (<i className="fa fa-2x fa-chevron-left"></i>) : ''}</button>
          <button className="slide-next d-none d-lg-block" onClick={_next}>{next ? (<i className="fa fa-2x fa-chevron-right"></i>) : ''}</button>
        </div>
      ) : ''}
    </div>
  );
};

/**
 * Item Content Newslatter
 */
export function ItemContentNewslatter({ block }) {
  return (
    <div className={`item-content ${block.class || ''}`}>
      <div className="container-lg">
        <div className="row">
          <div className="col">
            <div className="h2">{block.title}</div>
            <div className="h6">{block.label}</div>
            <p>{block.value}</p>
            <div className="input-group">
              <input className="form-control form-control-lg" placeholder={block.email} />
              <button className="btn btn-default">
                <i className="fa fa-fw fa-solid fa-plus"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Item Product
 */
export function ItemProduct({ product }) {
  const { setCartProduct } = useContext(UserContext);

  return (
    <div className="item-product item-product-table">
      <div className="container-lg">
        <div className="row">
          <div className="col-lg-4 order-lg-1">
            <div className="row">
              <div className="col">
                {product.title ? formatTitle(product.title) : ''}
                {product.label ? formatLabel(product.label) : ''}
                {product.price ? formatPrice(product.price) : ''}
                {product.price ? (
                  <div className="d-grid">
                    <button className="btn btn-primary" onClick={($e) => { setCartProduct(product, '+1'); $e.preventDefault() }}>{i18n('cart_insert')}</button>
                  </div>
                ) : ''}
              </div>
            </div>
          </div>
          <div className="col-lg-8">
            {product.images?.length ? (<ItemContentSlide slide={{ class: 'item-content-slide fluid', items: product.images.map((image) => ({ image })) }} />) : ''}
            {(product.attributes || []).map((item, i) => (
              <div className={`${item.class}`} key={`${i}`}>
                <table className="table" key={`${i}`}>
                  <thead><tr><th>{item.index ? formatTitle(item.index) : ''}</th></tr></thead>
                  <tbody><tr><td>{item.value ? formatValue(item.value) : ''}</td></tr></tbody>
                </table>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Node
 */
export function Node({ node }) {
  const { setCartProduct } = useContext(UserContext);

  return node ? (
    <div className={`node ${node.class || 'default'}`}>
      {node.badge ? formatBadge(node.badge) : ''}
      {node.image ? formatImage(node.image, node.title) : ''}
      {node.title ? formatTitle(node.title) : ''}
      {node.label ? formatLabel(node.label) : ''}
      {node.price ? formatPrice(node.price) : ''}
      {node.price ? (
        <div className="d-grid">
          <button className="btn btn-primary" onClick={($e) => { setCartProduct(node, '+1'); $e.preventDefault() }}>{i18n('cart_insert')}</button>
        </div>
      ) : ''}
    </div>
  ) : '';
};

/**
 * Card
 */
export function Card({ card }) {
  const { setCartProduct } = useContext(UserContext);

  return card ? (
    <Link className={`card ${card.class || 'default'}`} to={`/${card.route}`}>
      {card.badge ? formatBadge(card.badge) : ''}
      {card.image ? formatImage(card.image, card.title || card.label) : ''}
      {card.title || card.label ? (
        <div className="card-body">
          {card.title ? formatTitle(card.title) : ''}
          {card.label ? formatLabel(card.label) : ''}
          {card.price ? formatPrice(card.price) : ''}
        </div>
      ) : ''}
      {card.price ? (
        <div className="d-grid">
          <button className="btn btn-primary" tabIndex={-1} onClick={($e) => { setCartProduct(card, '+1'); $e.preventDefault() }}>{i18n('cart_insert')}</button>
        </div>
      ) : ''}
    </Link>
  ) : '';
};

/**
 * Shop Dialog
 */
export function ShopDialog({ close }) {
  const [items, setItems] = useState([]);
  const { config, setShop } = useContext(UserContext);

  const _fetch = () => {
    setItems(config.shops);
  };

  const _click = (_item) => {
    setShop(_item);
    close();
  };

  useEffect(_fetch, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="shop-dialog-content">
      {items.map((item, i) => (
        <Link className="card d-flex flex-row" to={`/${item.route || ''}`} onClick={() => _click(item)} key={`${i}`}>
          <div className="d-flex flex-column">
            {item.image ? formatImage(item.image, item.title) : ''}
          </div>
          <div className="d-flex flex-column flex-grow-1">
            {item.title ? formatTitle(item.title) : ''}
            {item.label ? formatLabel(item.label) : ''}
          </div>
        </Link>
      ))}
    </div>
  );
};

/**
 * Cart Dialog
 */
export function CartDialog({ close }) {
  const { cart, setCartProduct } = useContext(UserContext);

  const _click = (_item) => {
    if (_item.close) {
      close();
    }
  };

  return (
    <div className="cart-dialog-content">
      {(cart.items || []).map((item, i) => (
        <Link className="card d-flex flex-row" to={`/${item.route}`} onClick={() => _click(item)} key={`${i}`}>
          <div className="d-flex flex-column">
            {item.image ? formatImage(item.image, item.title) : ''}
          </div>
          <div className="d-flex flex-column flex-grow-1">
            {item.title ? formatTitle(item.title) : ''}
            {item.label ? formatLabel(item.label) : ''}
            <div className="d-flex flex-row gap-1 mt-auto">
              <div className="d-flex flex-row flex-grow-1 gap-1">
                <strong>{item.quantity}</strong><small>x</small>{formatPrice(item.total)}
              </div>
              <button className="btn btn-circle btn-dark" onClick={($e) => { setCartProduct(item, '+1'); $e.preventDefault() }}>
                <i className="fa fa-plus"></i>
              </button>
              <button className="btn btn-circle btn-dark" onClick={($e) => { setCartProduct(item, '-1'); $e.preventDefault() }}>
                <i className="fa fa-minus"></i>
              </button>
              <button className="btn btn-circle btn-danger" onClick={($e) => { setCartProduct(item, 0); $e.preventDefault() }}>
                <i className="fa fa-trash"></i>
              </button>
            </div>
          </div>
        </Link>
      ))}
      {cart.items?.length && cart.total ? (
        <div className="p-2">
          <div className="">Total: {formatPrice(cart.total)}</div>
          <div className="d-grid">
            <button className="btn btn-primary">Finalizar compra</button>
          </div>
        </div>
      ) : ''}
    </div>
  );
};

/**
 * User Dialog
 */
export function UserDialog({ close }) {
  return (
    <div className="user-dialog-content">
      <SignInForm />
    </div>
  );
};

/**
 * Menu Dialog
 */
export function MenuDialog({ close }) {
  const [menu, setMenu] = useState([]);

  const _fetch = () => {
    getProductsCategories().then(setMenu).catch(console.error);
  };

  useEffect(_fetch, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="menu-dialog-content">

      {/* Menu XS */}
      <div className="d-block d-lg-none">
        <div className="me-3">
          {menu.map((tree, t) => (
            <div key={`menu-sm-${t}`}>
              <button className="btn btn-default" data-bs-toggle="collapse" data-bs-target={`.menu-sm-${t}`}>{`${tree.title}`}</button>
              <div className={`menu-sm-${t} collapse`}>
                {tree.items ? tree.items.map((menu, m) => (
                  <div className="d-block" key={`menu-lg-${t}-${m}`}>
                    <Link to={`/${tree.route}/${menu.route}`} onClick={close}>{`${menu.title}`}</Link>
                  </div>
                )) : ''}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Menu SM */}
      <div className="d-none d-lg-flex">
        <div className="flex-column nav nav-pills me-3">
          {menu.map((tree, t) => (
            <div className="d-grid gap-2" key={`${t}`}>
              <button className="btn btn-light" data-bs-toggle="pill" data-bs-target={`.menu-lg-${t}`} onMouseEnter={(event) => event.target.click()}>{tree.title}</button>
            </div>
          ))}
        </div>
        <div className="flex-column tab-content">
          {menu.map((tree, t) => (
            <div className={`menu-lg-${t} tab-pane fade`} key={`${t}`}>
              <div className="d-flex">
                {tree.items ? tree.items.map((menu, m) => (
                  <div key={`${t}-${m}`}>
                    <Link className="d-block m-1 p-1 fw-bold" to={`/${tree.route}/${menu.route}`} onClick={close}>{menu.title}</Link>
                    {menu.items ? menu.items.map((item, i) => (
                      <Link className="d-block m-1 p-1" key={`${t}-${m}-${i}`} to={`/${tree.route}/${menu.route}/${item.route}`} onClick={close}>
                        {item.title} <span className="small text-muted">&nbsp;({item.total || 0})</span>
                      </Link>
                    )) : ''}
                  </div>
                )) : ''}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

/**
 * Sign In Form
 */
export function SignInForm() {
  return (
    <form>
      <div className="form-floating mb-3">
        <input className="form-control" id="inputUsername" type="username" placeholder="Correo electrónico" />
        <label className="text-control" htmlFor="inputUsername">Correo electrónico</label>
      </div>
      <div className="form-floating mb-3">
        <input className="form-control" id="inputPassword" type="password" placeholder="Contraseña" />
        <label className="text-control" htmlFor="inputPassword">Contraseña</label>
      </div>
      <div className="form-check mb-3">
        <input className="form-check-input" id="rememberUser" type="checkbox" value="" />
        <label className="form-check-label" htmlFor="rememberUser">
          Recordarme en este equipo
        </label>
      </div>
      <div className="d-grid">
        <button className="btn btn-primary" type="submit">Ingresar</button>
        <div className="mt-2 text-center">
          <Link className="small" href="#">Olvidé mi contraseña</Link>
        </div>
      </div>
    </form>
  );
};

/**
 * Sign Up Form
 */
export function SignUpForm() {
  return (
    <form>
      <div className="form-floating mb-3">
        <input className="form-control" id="inputName" type="name" placeholder="Nombre" />
        <label className="text-control" htmlFor="inputName">Nombre</label>
      </div>
      <div className="form-floating mb-3">
        <input className="form-control" id="inputUsername" type="username" placeholder="Correo electrónico" />
        <label className="text-control" htmlFor="inputUsername">Correo electrónico</label>
      </div>
      <div className="form-floating mb-3">
        <input className="form-control" id="inputPassword" type="password" placeholder="Contraseña" />
        <label className="text-control" htmlFor="inputPassword">Contraseña</label>
      </div>
      <div className="d-grid">
        <button className="btn btn-primary" type="submit">Crear cuenta</button>
        <div className="mt-2 text-center">
          <Link className="small" href="#">Ya tengo una cuenta</Link>
        </div>
      </div>
    </form>
  );
};

/**
 * Popover
 */
export function Popover() {
  const { info } = useContext(UserContext);
  const ref = createRef();

  const _fetch = () => {
    if (info) {
      const toast = window.bootstrap.Toast.getOrCreateInstance(ref.current);
      toast.show();
    }
  };

  useEffect(_fetch, [info]); // eslint-disable-line react-hooks/exhaustive-deps

  return info ? (
    <div className="toast-container position-fixed bottom-0 end-0 p-3">
      <div ref={ref} className={`toast border-0 text-bg-${info.type}`}>
        <div className="d-flex">
          <div className="toast-body">{info.text}</div>
          <button type="button" className="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
      </div>
    </div>
  ) : '';
};

/**
 * Format Title
 */
export function formatTitle(title = '', className = 'item-title') {
  title = formatValue(title, className);
  return title;
}

/**
 * Format Label
 */
export function formatLabel(label = '', className = 'item-label') {
  label = formatValue(label);
  return label;
}

/**
 * Format Price
 */
export function formatPrice(price = '', className = 'item-price') {
  price = parseFloat(price || 0);
  price = Math.round((price + Number.EPSILON) * 100) / 100;
  price = price.toString().split('.');
  price = price[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ',' + ((price[1] ? price[1].substring(0, 2) : '') + '00').substring(0, 2);

  return (
    <div className={className}>${price}</div>
  );
}

/**
 * Format Image
 */
export function formatImage(image = '', title = '', className = 'item-image') {
  const _image = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAQAAAAnOwc2AAAAEUlEQVR42mP8/58BAzAOZUEA5OUT9xiCXfgAAAAASUVORK5CYII=';
  const _title = 'Image';

  return (
    <img className={className} src={image || _image} alt={title || _title} onError={($e) => $e.target.src = _image} />
  );
}

/**
 * Format Badge
 */
export function formatBadge(badge = '', className = 'item-badge') {
  return (
    <div className={className}><i className={badge}></i></div>
  );
}

/**
 * Format Value
 */
export function formatValue(value, className) {
  value = `${value || ''}`.trim();
  value = value.replace(/\{\{@year\}\}/g, `${new Date().getFullYear()}`);

  // Replace Text: "- A. X.", "A. X."
  // value = value.replace(/ ([A-Za-z0-9]+\) [A-Za-z].*?[;.])/g, '<br />$1');
  // value = value.replace(/ (- [A-Za-z].*?[;.])/g, '<br />$1');

  // Replace Text: “...“
  value = value.replace(/“(.*?)”/g, '“<b>$1</b>”');

  // Replace URL
  value = value.replace(/(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim, '<a href="$1" target="_blank"><i>$1</i></a>');
  value = value.replace(/(^|[^/])(www\.[\S]+(\b|$))/gim, '$1<a href="https://$2" target="_blank"><i>$2</i></a>');

  // Replace E-mail
  value = value.replace(/(([a-zA-Z0-9-_.])+@[a-zA-Z_]+?(\.[a-zA-Z]{2,6})+)/gim, '<a href="mailto:$1"><i>$1</i></a>');

  if (value.match(/\|/)) {
    const rows = value.split(/\|/);
    const list = [];
    for (let row of rows) {
      row = row.replace(/^(.*?)$/g, '<tr><td>$1</td></tr>').replace(/;/g, '</td><td>');
      row = row.replace(/<td>\*(.*?)<\/td>/g, '<th>$1</th>');
      list.push(row);
    }
    value = list.length ? `<table class="table table-sm table-hover table-striped table-borderless">${list.join('')}</table>` : '';
    value = value.replace(/<th>/g, '<th>');
    value = value.replace(/<td>/g, '<td>');
  }

  const match = `${value || ''}`.match(/\[(.*?)\]\((.*?)\)/);
  if (match) {
    return (<div className={className}><Link to={`${match[2]}`}>{`${match[1]}`}</Link></div>);
  } else {
    return (<div className={className} dangerouslySetInnerHTML={{ __html: value }} />);
  }
}
