import { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserContext, PageContent, ShopDialog, CartDialog, UserDialog, MenuDialog, formatImage, formatTitle, formatLabel, formatPrice } from './Pages';
import { i18n, getProducts } from '../utils';

export function Header() {
  const { site, shop, cart } = useContext(UserContext);
  const [text, setText] = useState('');
  const [find, setFind] = useState([]);

  const navigate = useNavigate();

  const _find = (query) => {
    setText(query);
    setFind([]);
    if (query.length > 1) {
      const filter = { query };
      filter.start = 0;
      filter.limit = 5;
      getProducts(filter).then(setFind).catch(console.error);
    }
  };

  return (
    <header>

      {/* Header Menu 1 */}
      <div className="header-1">
        <div className="container-lg d-flex justify-content-start align-items-center">

          {/* Shop Button */}
          <div className="header-form flex-fill">
            <button className="btn btn-default" data-bs-toggle="offcanvas" data-bs-target=".shop-dialog">
              <strong>{shop.title}, {shop.label}</strong>&nbsp;<i className="fa-solid fa-fw fa-lg fa-pen-to-square "></i><span className="d-none d-lg-inline fw-bold">{i18n('shop_change')}</span>
            </button>
          </div>
        </div>
      </div>

      {/* Header Menu 2 */}
      <div className="header-2">
        <div className="container-lg d-flex justify-content-start align-items-center">

          {/* Logo */}
          <div className="header-logo-button d-none d-lg-block">
            <Link to="/"><img src={`${site.image}`} alt={`${site.title}`} /></Link>
          </div>

          {/* Menu Button */}
          <div className="header-menu-button">
            <button className="btn btn-default" data-bs-toggle="offcanvas" data-bs-target=".menu-dialog">
              <i className="fa-solid fa-fw fa-lg fa-bars"></i><span className="d-none d-lg-inline fw-bold">{i18n('menu')}</span>
            </button>
          </div>

          {/* Home Button */}
          <div className="header-menu-button d-block d-lg-none">
            <button className="btn btn-default" onClick={() => navigate('/')}>
              <i className="fa-solid fa-fw fa-lg fa-house"></i><span className="d-none d-lg-inline fw-bold">{i18n('home')}</span>
            </button>
          </div>

          {/* Form */}
          <div className="header-form-search flex-fill">
            <div className="input-group">
              <input className="form-control form-control-lg" placeholder={i18n('form_search')} value={text} onChange={($e) => _find($e.target.value)} />
              <button className="btn btn-default d-none d-lg-inline" onClick={() => _find('')}>
                <i className={`fa-solid fa-fw fa-lg ${text ? 'fa-close' : 'fa-search'}`}></i>
              </button>
              {find.length ? (
                <ul className="dropdown-menu">
                  {find.map((item, i) => (
                    <li key={`${i}`}>
                      <Link className="dropdown-item d-flex flex-row align-items-center" to={`/${item.route}`} onClick={() => _find('')} key={`${i}`}>
                          {item.image ? formatImage(item.image, item.title || item.label) : ''}
                          {item.title ? formatTitle(item.title) : ''}
                          {item.label ? formatLabel(item.label) : ''}
                          {item.price ? formatPrice(item.price) : ''}
                      </Link>
                    </li>
                  ))}
                </ul>
              ) : ''}
            </div>
          </div>

          {/* User Button */}
          <div className="header-user-button">
            <button className="btn btn-default" data-bs-toggle="offcanvas" data-bs-target=".user-dialog">
              <i className="fa-solid fa-fw fa-lg fa-user"></i><span className="d-none d-lg-inline fw-bold">{i18n('user')}</span>
            </button>
          </div>

          {/* Cart Button */}
          <div className="header-cart-button">
            <button className="btn btn-default" data-bs-toggle="offcanvas" data-bs-target=".cart-dialog">
              <i className="fa-solid fa-fw fa-lg fa-bag-shopping"></i><span className="d-none d-lg-inline fw-bold">{i18n('cart')} {cart.items?.length ? (<span className="badge bg-info">{cart.items.length}</span>) : ''}</span>
            </button>
          </div>
        </div>
      </div>

      {/* Shop Dialog */}
      <div className="shop-dialog offcanvas offcanvas-end">
        <div className="offcanvas-header">
          <h5>{i18n('shop')}</h5>
          <button className="btn-close" data-bs-dismiss="offcanvas"></button>
        </div>
        <div className="offcanvas-body">
          <ShopDialog close={() => window.bootstrap.Offcanvas.getInstance('.shop-dialog').hide()} />
        </div>
      </div>

      {/* Cart Dialog */}
      <div className="cart-dialog offcanvas offcanvas-end">
        <div className="offcanvas-header">
          <h5>{i18n('cart')}</h5>
          <button className="btn-close" data-bs-dismiss="offcanvas"></button>
        </div>
        <div className="offcanvas-body">
          <CartDialog close={() => window.bootstrap.Offcanvas.getInstance('.cart-dialog').hide()} />
        </div>
      </div>

      {/* User Dialog */}
      <div className="user-dialog offcanvas offcanvas-end">
        <div className="offcanvas-header">
          <h5>{i18n('user')}</h5>
          <button className="btn-close" data-bs-dismiss="offcanvas"></button>
        </div>
        <div className="offcanvas-body">
          <UserDialog close={() => window.bootstrap.Offcanvas.getInstance('.user-dialog').hide()} />
        </div>
      </div>

      {/* Menu Dialog */}
      <div className="menu-dialog offcanvas offcanvas-start">
        <div className="offcanvas-header">
          <h5>{i18n('menu')}</h5>
          <button className="btn-close" data-bs-dismiss="offcanvas"></button>
        </div>
        <div className="offcanvas-body">
          <MenuDialog close={() => window.bootstrap.Offcanvas.getInstance('.menu-dialog').hide()} />
        </div>
      </div>

      <PageContent route="header" />
    </header>
  );
};
