import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { UserContextProvider, PageContent, PageProduct, PageProducts } from './components/Pages';
import { getConfig } from './utils';

export default function App() {
  const [config, setConfig] = useState(false);

  const _fetch = () => {
    getConfig().then(setConfig).catch(console.error);
  }

  useEffect(_fetch, []); // eslint-disable-line react-hooks/exhaustive-deps

  return config ? (
    <UserContextProvider config={config}>
      <Router basename={`${config.basename}`}>
        <Header />
        <Routes>
          <Route path="/" exact element={(<PageContent route="home" />)} />
          <Route path="/:route/c" element={(<PageContent />)} />
          <Route path="/:route/p" element={(<PageProduct />)} />
          <Route path="/:route/*" element={(<PageProducts />)} />
        </Routes>
        <Footer />
      </Router>
    </UserContextProvider>
  ) : '';
};
